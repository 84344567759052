import Router from 'next/router';
import { loadScript } from './loadScript';

const GA_ID = process.env.GA_ID || '';

type AnalyticsPageView = {
  path: string;
  fromPath?: string;
};

function logPage(pageView: AnalyticsPageView): void {
  gtag('config', GA_ID, {
    page_path: pageView.path,
    page_title: document.title,
  });
}

function gtag(..._args: any[]): void {
  window.dataLayer.push(arguments);
}

function handleRouteChange() {
  Router.events.on('routeChangeComplete', (path: string) => {
    logPage({
      path,
    });
  });
}

export function initialize() {
  window.dataLayer = [];

  gtag('js', new Date());
  gtag('config', GA_ID);

  loadScript(`https://www.googletagmanager.com/gtag/js?id=${GA_ID}`)
    .then(() => {
      handleRouteChange();
    })
    .catch(() => {
      console.warn('Could not load google tagmanager');
    });
}
