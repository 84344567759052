import { useRef, useState, useEffect, memo } from 'react';
import { useSelector } from '@store/index';
import { getStations } from '@store/stations/selectors';
import ChevronLeftIcon from '@svg/icon_chevron_left.svg';
import ChevronRightIcon from '@svg/icon_chevron_right.svg';
import { Station } from './Station';
import styles from './StationScroll.module.scss';

export const StationScroll = memo(function StationScroll() {
  const ref = useRef<HTMLDivElement>(null);
  const stations = useSelector(getStations);
  const [scrollBy, setScrollBy] = useState(114);
  const [prevEnabled, setPrevEnabled] = useState(false);
  const [nextEnabled, setNextEnabled] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (!el) return;

    function update() {
      if (!el) return;
      const children = Array.from(el.children) as HTMLElement[];
      if (children.length < 2) return;

      const { width } = el.getBoundingClientRect();
      const [first, second] = children;
      const { scrollWidth, scrollLeft } = el;

      const offset = second.offsetLeft - first.offsetLeft;
      const max = scrollWidth - width;
      const treshold = 8;

      setScrollBy(offset);
      setPrevEnabled(scrollLeft > treshold);
      setNextEnabled(scrollLeft < max - treshold);
    }
    update();

    // Mousewheeling in Chrome causes massive glitches when
    // scroll-snap is used in combination with a hover
    // transition on the items so we disable it for now.
    const preventMouseWheelInChrome = (e: WheelEvent) => {
      // @ts-ignore-next-line
      if (!!window.chrome) {
        e.preventDefault();
      }
    };

    el.addEventListener('wheel', preventMouseWheelInChrome, false);
    el.addEventListener('scroll', update);
    window.addEventListener('resize', update);
    return () => {
      el.removeEventListener('wheel', preventMouseWheelInChrome, false);
      el.removeEventListener('scroll', update);
      window.removeEventListener('resize', update);
    };
  }, []);

  return (
    <div className={styles.StationScroll}>
      <div className={styles.StationList} ref={ref}>
        {stations.map((station) => (
          <Station key={station.id} station={station} />
        ))}
      </div>

      <button
        className={styles.ArrowLeft}
        disabled={!prevEnabled}
        onClick={() => {
          const el = ref.current;
          if (!el) return;
          el.scrollBy({ left: -scrollBy });
        }}
      >
        <ChevronLeftIcon />
      </button>
      <button
        className={styles.ArrowRight}
        disabled={!nextEnabled}
        onClick={() => {
          const el = ref.current;
          if (!el) return;
          el.scrollBy({ left: scrollBy });
        }}
      >
        <ChevronRightIcon />
      </button>
    </div>
  );
});
