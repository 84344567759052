import { NavigationItem, ParsedNavigationItem } from '@api/types/types';
import { State } from '@store/index';
import { isExternalUrl } from '@utils/isExternalUrl';
import { createSelector } from 'reselect';

export const getSettings = (state: State) => state.settings;

export const getMainStationId = createSelector(
  getSettings,
  (settings) => settings.main_station_id
);

export const getCurrentProgram = createSelector(
  getSettings,
  (settings) => settings.currentProgram?.data
);

function parseNavigation(items: NavigationItem[]): ParsedNavigationItem[] {
  return items.map((item) => ({
    id: item.id,
    title: item.title,
    href: item.type === 'url' ? item.url : `/${item.slug}`,
    external: item.type === 'url' && isExternalUrl(item.url),
  }));
}

export const getNavigation = createSelector(getSettings, (settings) => {
  const { top, hamburger, leftFooter, middleFooter, rightFooter } =
    settings.navigation.data;

  return {
    top: parseNavigation(top.data),
    hamburger: parseNavigation(hamburger.data),
    leftFooter: parseNavigation(leftFooter.data),
    middleFooter: parseNavigation(middleFooter.data),
    rightFooter: parseNavigation(rightFooter.data),
  };
});
