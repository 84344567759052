import { throttle } from '@utils/throttle';
import { useEffect, useState } from 'react';

type Size = {
  windowWidth: number;
  windowHeight: number;
};

function readSize(): Size {
  return typeof window === 'object'
    ? {
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      }
    : { windowWidth: 800, windowHeight: 800 };
}

export function useWindowSize(): Size {
  const [size, setSize] = useState(readSize);

  useEffect(() => {
    const handleResize = throttle(function onHandleResize() {
      setSize(readSize());
    }, 200);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return size;
}
