import { fetchSettings } from '@api/helpers';
import { setSettings } from '@store/settings/actions';
import { setStations } from '@store/stations/actions';
import { Dispatch } from 'react';
import { RootAction } from '@store/index';

export async function fetchSharedData(dispatch: Dispatch<RootAction>) {
  const settings = await fetchSettings();
  const stations = settings?.stations?.data || [];

  dispatch(setSettings(settings));
  dispatch(setStations(stations));

  return { settings, stations };
}
