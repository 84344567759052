import styles from './HeaderOffset.module.css';

type Props = {
  children: React.ReactNode;
  offset: number;
};

export function HeaderOffset({ children, offset }: Props) {
  return (
    <div className={styles.HeaderOffset} style={{ paddingTop: offset }}>
      {children}
    </div>
  );
}
