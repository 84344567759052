import React from 'react';
import styles from './Footer.module.css';
import { FooterLinks } from './FooterLinks';

export function Footer() {
  return (
    <div className={styles.Footer}>
      <div className={styles.FooterInner}>
        <FooterLinks />
      </div>
    </div>
  );
}
