import { usePersistedState } from './usePersistedState';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { getCookieConsent } from '@store/cookies/selectors';
import { useEffect } from 'react';

// Triton Listener ID used to track the session. We only use this to prevent
// prerolls when restarting the stream / switching stations, no actual user
// tracking. Triton docs:
// https://userguides.tritondigital.com/spc/stream/listener-id-management.html
export function useListenerId(): string | undefined {
  const consent = useSelector(getCookieConsent);
  const [lsid, setLsid] = usePersistedState<{ value?: string }>(
    'radioplayer:lsid',
    {}
  );
  const value = lsid?.value;

  useEffect(() => {
    if (consent === 'accepted' && !value) {
      setLsid({ value: `app:${uuidv4()}` });
    }
  }, [consent, value]);

  return value;
}
