type Throttle = (
  fn: Function,
  threshold: number,
  context?: object
) => () => void;

export const throttle: Throttle = (
  fn: Function,
  threshold: number = 100,
  context?: object
) => {
  let last = 0;
  let deferTimer: number;
  return (...args: any[]) => {
    const now = Date.now();
    if (last && now < last + threshold) {
      window.clearTimeout(deferTimer);
      deferTimer = window.setTimeout(() => {
        last = now;
        fn.apply(context, args);
      }, threshold);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
};
