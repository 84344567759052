import { usePersistedState } from '@hooks/usePersistedState';

export type CountryCodeType = 'BE' | 'NL' | 'FR';

export type ZipcodeResultType =
  | {
      zip: string;
      countryCode: CountryCodeType;
      type: 'saved';
    }
  | {
      type: 'skipped';
    }
  | {
      type: 'pending';
    };

// State has a default value but can be undefined when
// someone manually deletes the entry from localstorage.
export function useZipcodeResult() {
  return usePersistedState<ZipcodeResultType | null>(
    'radioplayer:zipcode_with_countrycode',
    {
      type: 'pending',
    }
  );
}
