import { Menu } from '@components/Navigation/Menu';
import { Navigation } from '@components/Navigation/Navigation';
import { useClickOutside } from '@hooks/useClickOutside';
import LogoSvg from '@svg/logo.svg';
import Link from 'next/link';
import { useRef, useState, useEffect } from 'react';
import styles from './Header.module.scss';
import { useRouter } from 'next/router';
import { StationScroll } from './StationScroll';

type Props = {
  onChangeHeight: (height: number) => void;
};

function getScrollTop() {
  return window.scrollY || document.documentElement.scrollTop;
}

export function Header({ onChangeHeight }: Props) {
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  useClickOutside(innerRef, () => {
    setOpen(false);
  });

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    let prevScroll = getScrollTop();
    let currScroll = 0;
    let direction = 0;
    let prevDirection = 0;

    const toggle = (direction: number, scroll: number) => {
      const offset = 40;
      const treshold = el.offsetHeight + offset;

      if (direction === 2 && scroll > treshold) {
        prevDirection = direction;
        el.classList.toggle(styles.hidden, true);
      } else if (direction === 1) {
        prevDirection = direction;
        el.classList.toggle(styles.hidden, false);
      }
    };

    const checkScroll = () => {
      currScroll = getScrollTop();

      if (currScroll > prevScroll) {
        direction = 2;
      } else if (currScroll < prevScroll) {
        direction = 1;
      }

      if (direction !== prevDirection) {
        toggle(direction, currScroll);
      }

      prevScroll = currScroll;
    };

    window.addEventListener('scroll', checkScroll);
    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, [setOpen]);

  useEffect(() => {
    const onChange = () => setOpen(false);
    router.events.on('routeChangeComplete', onChange);
    return () => {
      router.events.off('routeChangeComplete', onChange);
    };
  }, [router.events]);

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    const observer = new ResizeObserver(() => onChangeHeight(el.offsetHeight));
    observer.observe(el);
    return () => {
      observer.disconnect();
    };
  }, [onChangeHeight]);

  return (
    <header className={styles.Header} ref={ref}>
      <div className={styles.HeaderInner} ref={innerRef}>
        <Link href="/">
          <a title="Home">
            <LogoSvg height={60} className={styles.HeaderLogo} />
          </a>
        </Link>
        <Navigation onToggleMenu={() => setOpen(!open)} />
        <Menu open={open} />
      </div>

      <StationScroll />
    </header>
  );
}
