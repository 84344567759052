// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN;
const SENTRY_DEBUG = process.env.SENTRY_DEBUG;

Sentry.init({
  dsn: SENTRY_DSN,
  debug: SENTRY_DEBUG === 'on',
  environment: 'client',
  tracesSampleRate: 0,
  sampleRate: 0.2,
  attachStacktrace: true,
  ignoreErrors: [
    // Ignore errors from within video.js.
    /Failed to set the 'endTime' property on 'TextTrackCue'/,
    /TextTrackCue.endTime setter/,
    // Ignore openplayerjs errors
    /NotAllowedError: play\(\)/,
    /AbortError: The play\(\)/,
    /The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission./,
  ],
});
