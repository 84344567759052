import styles from './Tag.module.css';
import { cx } from '@utils/cx';

type Props = {
  children: React.ReactNode;
  size?: 'small' | 'medium';
};

export function Tag({ children, size = 'medium' }: Props) {
  return <div className={cx(styles.Tag, styles[size])}>{children}</div>;
}

export function TagList({ children }: Props) {
  return <div className={styles.TagList}>{children}</div>;
}
