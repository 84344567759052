import { State } from '@store/index';
import { createSelector } from 'reselect';
import { getMainStationId } from '@store/settings/selectors';

export const getStations = (state: State) => state.stations;

export const getMainStation = createSelector(
  getStations,
  getMainStationId,
  (stations, mainStationId) =>
    stations.find((station) => station.id === mainStationId)
);
