type Extensions = 'jpg' | 'webp' | 'png';

class ImageUrl {
  private url: URL;

  private path: string;

  private filename: string;

  private ratio = '16-9';

  private width = 1000;

  private quality = 90;

  private extension = 'jpg';

  constructor(url: string) {
    this.url = new URL(url);

    const path = this.url.pathname.split('/');
    const filename = path.pop();

    if (!filename) {
      throw Error('Image URL is not valid');
    }

    this.filename = filename;

    this.path = path.join('/');
  }

  setRatio(ratio: string) {
    this.ratio = ratio.replace(':', '-');

    return this;
  }

  setWidth(width: number) {
    this.width = width;

    return this;
  }

  setQuality(quality: number) {
    this.quality = quality;

    return this;
  }

  setExtension(extension: Extensions) {
    this.extension = extension;

    return this;
  }

  toString(extension?: Extensions) {
    const [id, hash] = this.filename.split('.');

    if (extension) {
      this.extension = extension;
    }

    return `${this.url.origin + this.path}/${id}.${hash}.${this.ratio}.${
      this.width
    }.${this.quality}.${this.extension}`;
  }
}

export function imageUrl(url: string) {
  return new ImageUrl(url);
}
