import FacebookIcon from '@assets/svg/facebook.svg';
import InstagramIcon from '@assets/svg/instagram.svg';
import TiktokIcon from '@assets/svg/tiktok.svg';
import { useMaxTopNavCount } from './useMaxTopNavCount';

import { useSelector } from '@store/index';
import { getNavigation } from '@store/settings/selectors';
import { cx } from '@utils/cx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import styles from './Menu.module.css';

type Props = {
  open: boolean;
};

export function Menu({ open = false }: Props) {
  const router = useRouter();
  const tabIndex = open ? 0 : -1;
  const maxCount = useMaxTopNavCount();
  const { top, hamburger } = useSelector(getNavigation);
  const max = Math.min(top.length, maxCount);
  const all = top.concat(hamburger).slice(max);

  return (
    <div className={cx(styles.Menu, open && styles.open)}>
      <ul>
        {all.map(({ href, title, external, id }) => (
          <li
            key={id}
            className={cx(router.asPath === href && styles.isActive)}
          >
            <Link href={href}>
              <a
                tabIndex={tabIndex}
                target={external ? '_blank' : undefined}
                rel={external ? 'noopener noreferrer' : undefined}
              >
                <span>{title}</span>
              </a>
            </Link>
          </li>
        ))}
      </ul>

      <div className={styles.MenuIcons}>
        <MenuIcon title="Facebook" href="https://www.facebook.com/TOPradio/">
          <FacebookIcon height={20} />
        </MenuIcon>
        <MenuIcon title="TikTok" href="https://www.tiktok.com/@topradiobe">
          <TiktokIcon height={22} />
        </MenuIcon>
        <MenuIcon
          title="Instagram"
          href="https://www.instagram.com/topradio/?hl=nl"
        >
          <InstagramIcon height={22} />
        </MenuIcon>
      </div>
    </div>
  );
}

function MenuIcon({
  children,
  href,
  title,
}: {
  children: React.ReactNode;
  href: string;
  title: string;
}) {
  return (
    <a
      className={styles.MenuIcon}
      target="_blank"
      rel="noopener noreferrer"
      href={href}
      title={title}
    >
      {children}
    </a>
  );
}
