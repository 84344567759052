import { SettingsType } from '@api/types/types';
import { ActionType, createStandardAction } from 'typesafe-actions';

export const setSettings = createStandardAction(
  '[settings] setSettings'
)<SettingsType | null>();

const actions = { setSettings };

export type SettingsAction = ActionType<typeof actions>;
