import { ConsentType } from '@api/types/types';
import { RootAction } from '@store/index';
import { getType } from 'typesafe-actions';
import { setConsent } from './actions';
import { HYDRATE } from 'next-redux-wrapper';
import { storage } from '@utils/storage';

export type CookiesState = {
  consent: ConsentType;
};

const initialState: CookiesState = { consent: 'pending' };

export function cookiesReducer(
  state: CookiesState = initialState,
  action: RootAction
): CookiesState {
  switch (action.type) {
    case getType(setConsent): {
      return {
        ...state,
        consent: action.payload,
      };
    }

    case HYDRATE: {
      const consentTypes: ConsentType[] = ['pending', 'declined', 'accepted'];
      const consent = storage.get<ConsentType>('cookie_consent') || 'pending';

      return consent && consentTypes.includes(consent)
        ? { consent }
        : initialState;
    }
  }

  return state;
}
