import type { NowPlayingListType } from '@api/types/types';
import { RootAction } from '@store/index';
import { getType } from 'typesafe-actions';
import { setNowPlaying } from './actions';

export type NowPlayingState = NowPlayingListType;

export function nowPlayingReducer(
  state: NowPlayingState = {},
  action: RootAction
): NowPlayingState {
  switch (action.type) {
    case getType(setNowPlaying): {
      return action.payload || {};
    }
  }

  return state;
}
