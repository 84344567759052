import { StationItemType } from '@api/types/types';
import { isMobile } from '@utils/isMobile';

export function openRadioPlayer(station: StationItemType, height = 768) {
  const href = `/player/${station.slug}`;

  if (isMobile()) {
    return window.open(href);
  }

  return window.open(
    href,
    'player',
    `width=380,height=${height},location=no,toolbar=no,resizable=no,menubar=no`
  );
}
