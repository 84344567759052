import { waitForReceiver } from '@utils/chromecast';
import React, { useState, useEffect, createContext } from 'react';

export const ChromecastReceiverAvailableContext = createContext(false);

export function ChromecastReceiverAvailableProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isAvailable, onAvailabilityChange] = useState(false);

  useEffect(() => {
    waitForReceiver(onAvailabilityChange).catch((e) => {
      console.log(e);
    });
  }, []);

  return (
    <ChromecastReceiverAvailableContext.Provider value={isAvailable}>
      {children}
    </ChromecastReceiverAvailableContext.Provider>
  );
}
