import HamburgerIcon from '@assets/svg/hamburger.svg';
import Link from 'next/link';
import styles from './Navigation.module.css';
import { useRouter } from 'next/router';
import { useSelector } from '@store/index';
import { getNavigation } from '@store/settings/selectors';
import { useMaxTopNavCount } from './useMaxTopNavCount';

type Props = {
  onToggleMenu: () => void;
};

export function Navigation({ onToggleMenu }: Props) {
  const router = useRouter();
  const maxCount = useMaxTopNavCount();
  const { top } = useSelector(getNavigation);
  const max = Math.min(top.length, maxCount);
  const all = top.slice(0, max);

  return (
    <nav className={styles.Navigation}>
      <ul>
        {all.map(({ href, title, external }) => (
          <li
            key={title}
            className={router.asPath === href ? styles.isActive : undefined}
          >
            <Link href={href}>
              <a
                target={external ? '_blank' : undefined}
                rel={external ? 'noopener noreferrer' : undefined}
              >
                <span>{title}</span>
              </a>
            </Link>
          </li>
        ))}
      </ul>
      <button
        title="Menu"
        className={styles.NavigationButton}
        onClick={onToggleMenu}
      >
        <HamburgerIcon />
      </button>
    </nav>
  );
}
