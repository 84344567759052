import { Tag, TagList } from '@components/Tag/Tag';
import { useRadioPlayerPopup } from '@hooks/useRadioPlayerPopup';
import { useSelector } from '@store/index';
import { getNowPlaying } from '@store/nowPlaying/selectors';
import { getMainStation } from '@store/stations/selectors';
import PlayIcon from '@svg/play.svg';
import { imageUrl } from '@utils/imageUrl';
import Image from 'next/image';
import { memo, useEffect, useRef, useState } from 'react';
import styles from './Player.module.css';
import { PlayerButtons } from './PlayerButtons';

const ImageWithFallback = ({
  src,
  alt,
  width,
  height,
  fallbackSrc,
}: {
  src: string;
  alt: string;
  width: number;
  height: number;
  fallbackSrc: string;
}) => {
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return (
    <Image
      alt={alt}
      width={width}
      height={height}
      src={imgSrc}
      onError={() => {
        setImgSrc(fallbackSrc);
      }}
    />
  );
};

export const Player = memo(function Player() {
  const openRadioPlayer = useRadioPlayerPopup();
  const allNowPlaying = useSelector(getNowPlaying);
  const mainStation = useSelector(getMainStation);
  const nowPlaying = mainStation ? allNowPlaying[mainStation.slug] : null;
  const playerRef = useRef<HTMLDivElement>(null);
  const size = 208;

  return (
    <div className={styles.Player} ref={playerRef}>
      <div className={styles.PlayerInner}>
        <div className={styles.PlayerArtwork}>
          {nowPlaying && nowPlaying.album_art ? (
            <ImageWithFallback
              src={nowPlaying.album_art}
              alt={nowPlaying.artist}
              width={size}
              height={size}
              fallbackSrc={
                mainStation?.logo
                  ? imageUrl(mainStation.logo.data.crop)
                      .setRatio('1:1')
                      .setWidth(size)
                      .toString()
                  : 'logo_should_be_set'
              }
            />
          ) : mainStation?.logo ? (
            <Image
              src={imageUrl(mainStation.logo.data.crop)
                .setRatio('1:1')
                .setWidth(size)
                .toString()}
              alt={mainStation.title}
              width={size}
              height={size}
            />
          ) : null}
        </div>

        <div className={styles.PlayerControl}>
          <div className={styles.PlayerInfo}>
            {mainStation && (
              <>
                <div className={styles.PlayerChannelInfo}>
                  <TagList>
                    <Tag size="small">LIVE</Tag>
                  </TagList>
                  <span>Je luistert nu naar {mainStation.title}</span>
                </div>

                {nowPlaying?.title != null && (
                  <>
                    <h3>{nowPlaying.title}</h3>
                    <p>{nowPlaying.artist}</p>
                  </>
                )}
              </>
            )}
          </div>

          {mainStation && (
            <button
              className={styles.PlayerPlayButton}
              title="Pause"
              onClick={() => {
                openRadioPlayer(mainStation);
              }}
            >
              <PlayIcon height={48} />
            </button>
          )}

          <PlayerButtons />
        </div>
      </div>
    </div>
  );
});
