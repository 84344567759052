import { ParsedNavigationItem } from '@api/types/types';
import FacebookIcon from '@assets/svg/facebook.svg';
import InstagramIcon from '@assets/svg/instagram.svg';
import TiktokIcon from '@assets/svg/tiktok.svg';
import { useSelector } from '@store/index';
import { getNavigation } from '@store/settings/selectors';
import LogoSvg from '@svg/logo.svg';
import LogoCombellSvg from '@svg/logo_combell.svg';
import { cx } from '@utils/cx';
import Link from 'next/link';
import React, { memo } from 'react';
import styles from './Footer.module.css';

export const FooterLinks = memo(function FooterLinks() {
  const { leftFooter, middleFooter, rightFooter } = useSelector(getNavigation);

  return (
    <div className={styles.FooterLinks}>
      <FooterBar align="center">
        <LogoSvg height={60} />
        <div className={styles.FooterIcons}>
          <h2>Volg ons op</h2>
          <div className={styles.FooterIconsInner}>
            <ExternalLink
              href="https://www.facebook.com/TOPradio/"
              title="Facebook"
            >
              <FacebookIcon height={24} />
            </ExternalLink>
            <ExternalLink
              href="https://www.tiktok.com/@topradiobe"
              title="TikTok"
            >
              <TiktokIcon height={26} />
            </ExternalLink>
            <ExternalLink
              href="https://www.instagram.com/topradio/?hl=nl"
              title="Instagram"
            >
              <InstagramIcon height={24} />
            </ExternalLink>
          </div>
        </div>
      </FooterBar>

      <FooterBar>
        <FooterBlock>
          <FooterNav>
            {leftFooter.map((item) => (
              <FooterNavItem item={item} key={item.id} />
            ))}
          </FooterNav>
        </FooterBlock>

        <FooterBlock>
          <FooterNav>
            {middleFooter.map((item) => (
              <FooterNavItem item={item} key={item.id} />
            ))}
          </FooterNav>
        </FooterBlock>

        <FooterBlock>
          <FooterNav translucent>
            {rightFooter.map((item) => (
              <FooterNavItem item={item} key={item.id} />
            ))}
          </FooterNav>

          <div>
            <p>
              <span className="trademarks">© ®</span> TOPradio nv /{' '}
              <ExternalLink href="https://www.hellohelloradio.com">
                hellohelloradio.com
              </ExternalLink>{' '}
              bv
            </p>
            <p>
              Hosted by:{' '}
              <ExternalLink
                href="https://www.combell.be/"
                title="Combell hosting"
              >
                <LogoCombellSvg height={14} />
              </ExternalLink>
            </p>
          </div>
        </FooterBlock>
      </FooterBar>
    </div>
  );
});

function ExternalLink({
  href,
  title,
  children,
}: {
  href: string;
  title?: string;
  children: React.ReactNode;
}) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" title={title}>
      {children}
    </a>
  );
}

function FooterBlock({ children }: { children: React.ReactNode }) {
  return <div className={styles.FooterBlock}>{children}</div>;
}

function FooterNav({
  children,
  translucent,
}: {
  children: React.ReactNode;
  translucent?: boolean;
}) {
  return (
    <ul className={cx(styles.FooterNav, translucent && styles.translucent)}>
      {children}
    </ul>
  );
}

function FooterNavItem({ item }: { item: ParsedNavigationItem }) {
  return (
    <li className={styles.FooterNavItem}>
      <Link href={item.href}>{item.title}</Link>
    </li>
  );
}

function FooterBar({
  children,
  align,
}: {
  children: React.ReactNode;
  align?: 'start' | 'end' | 'center';
}) {
  return (
    <div className={cx(styles.FooterBar, align && styles[align])}>
      {children}
    </div>
  );
}
