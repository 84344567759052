import { fetchNowPlaying } from '@api/helpers';
import { NowPlayingListType } from '@api/types/types';
import { ChromecastReceiverAvailableProvider } from '@components/Chromecast/Chromecast';
import { CookieWall } from '@components/CookieWall/CookieWall';
import { Footer } from '@components/Footer/Footer';
import { Header } from '@components/Header/Header';
import { HeaderOffset } from '@components/Header/HeaderOffset';
import { Player } from '@components/Player/Player';
import { useCurrentUrl } from '@hooks/useCurrentUrl';
import { usePageVisible } from '@hooks/usePageVisible';
import { getCookieConsent } from '@store/cookies/selectors';
import { useDispatch, useSelector, wrapper } from '@store/index';
import { setNowPlaying } from '@store/nowPlaying/actions';
import { getSettings } from '@store/settings/selectors';
import * as analytics from '@utils/analytics';
import { fetchSharedData } from '@utils/fetchSharedData';
import { getOpenGraphImageVariations } from '@utils/openGraph';
import { DefaultSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import '../styles/globals.css';

function useAnalytics() {
  const [booted, setBooted] = useState(false);
  const cookieConsent = useSelector(getCookieConsent);

  useEffect(() => {
    if (cookieConsent === 'accepted' && !booted) {
      analytics.initialize();
      setBooted(true);
    }
  }, [cookieConsent, booted]);
}

function useSharedDataUpdate() {
  const dispatch = useDispatch();
  const visible = usePageVisible();
  const delay = 2.5 * 60 * 1000;

  useEffect(() => {
    let timeoutId = 0;

    function update() {
      if (visible) {
        fetchSharedData(dispatch)
          .catch((err) => console.error(err))
          .finally(() => {
            timeoutId = window.setTimeout(update, delay);
          });
      }
    }

    if (visible) {
      update();
    }

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [dispatch, visible, delay]);
}

function useServiceWorker() {
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', function () {
        navigator.serviceWorker.register('/sw.js').then(
          function (registration) {},
          function (err) {}
        );
      });
    }
  }, []);
}

function useNowPlaying() {
  const dispatch = useDispatch();
  const visible = usePageVisible();
  const router = useRouter();

  let delay = 60 * 1000;

  // Only retrieve now playing faster on the player (15 seconds)
  if (router.route === '/player/[slug]') {
    delay = 15 * 1000;
  }

  useEffect(() => {
    let timeoutId = 0;

    function update() {
      if (visible) {
        fetchNowPlaying()
          .then((nowPlaying: NowPlayingListType) =>
            dispatch(setNowPlaying(nowPlaying))
          )
          .catch((err) => console.error(err))
          .finally(() => {
            timeoutId = window.setTimeout(update, delay);
          });
      }
    }

    if (visible) {
      update();
    }

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [dispatch, visible, delay]);
}

function AppSeo() {
  const { url, path } = useCurrentUrl();
  const { seo_title, seo_description, seoImage } = useSelector(getSettings);
  const openGraphImages =
    seoImage && seoImage.data
      ? getOpenGraphImageVariations(seoImage.data, seo_title)
      : [];

  return (
    <DefaultSeo
      title={seo_title}
      titleTemplate={path === '/' ? seo_title : `%s | ${seo_title}`}
      description={seo_description}
      canonical={url}
      openGraph={{
        url,
        title: seo_title,
        description: seo_description,
        type: 'website',
        images: openGraphImages,
      }}
      twitter={{
        handle: '@TOPradio',
        site: '@TOPradio',
        cardType: 'summary_large_image',
      }}
      additionalLinkTags={[
        {
          rel: 'manifest',
          href: '/manifest.json',
        },
      ]}
    />
  );
}

function App({ Component, pageProps }: AppProps) {
  const { withoutLayout } = Component as any;
  const [headerHeight, setHeaderHeight] = useState(80);

  useSharedDataUpdate();
  useServiceWorker();
  useNowPlaying();
  useAnalytics();

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>

      <AppSeo />

      <ChromecastReceiverAvailableProvider>
        {withoutLayout ? (
          <Component {...pageProps} />
        ) : (
          <>
            <Header onChangeHeight={setHeaderHeight} />
            <HeaderOffset offset={headerHeight}>
              <Component {...pageProps} />
            </HeaderOffset>
            <Player />
            <Footer />
            <CookieWall />
          </>
        )}
      </ChromecastReceiverAvailableProvider>
    </>
  );
}

export default wrapper.withRedux(App);
