import { StationItemType } from '@api/types/types';
import { useSelector } from '@store/index';
import { getStations } from '@store/stations/selectors';
import { clamp } from '@utils/clamp';
import { openRadioPlayer } from '@utils/openRadioPlayer';

export function useRadioPlayerPopup() {
  const stations = useSelector(getStations);
  const gap = 5;
  const buttonHeight = 68;
  const count = clamp(stations.length - 1, 1, 6);
  const buttonListHeight = count * (gap + buttonHeight);

  return (station: StationItemType) => {
    return openRadioPlayer(station, buttonListHeight + 400);
  };
}
