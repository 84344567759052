/// React hook that works like useState but persists the state in localStorage.
/// It also takes care of synchronizing state between open windows. It saves
/// data in a JSON stringified object that contains a value. This way it
/// maintains the state's type.

import { SerializableValue } from '@api/types/serializable';
import { storage } from '@utils/storage';
import { useEffect, useMemo, useState } from 'react';

export function usePersistedState<T extends SerializableValue>(
  key: string,
  initialState: T
): [T, (arg: T) => void] {
  // Get the persisted state if it exists and memoize it.
  const persistedState = useMemo(() => {
    const item =
      typeof window === 'object' ? storage.get<{ value: T }>(key) : null;

    return item ? item.value : null;
  }, [key]);

  // Set the persisted state if it exists. Fallback to the passed initialState.
  const [state, set] = useState<T>(
    persistedState != null ? persistedState : initialState
  );

  // Add an event listener that syncs the state between open windows.
  useEffect(() => {
    const onStorage = (event: StorageEvent) => {
      if (event.key === key) {
        const value = event.newValue && JSON.parse(event.newValue).value;
        set(value);
      }
    };

    window.addEventListener('storage', onStorage);

    return () => {
      window.removeEventListener('storage', onStorage);
    };
  }, [key, initialState]);

  // Set the state and persist it using localStorage.
  const setState = (newState: T) => {
    set(newState);

    if (typeof window === 'object') {
      storage.set(key, { value: newState });
    }
  };

  return [state, setState];
}
