import { StationItemType } from '@api/types/types';
import { useRadioPlayerPopup } from '@hooks/useRadioPlayerPopup';
import { imageUrl } from '@utils/imageUrl';
import { isHex } from '@utils/isHex';
import Image from 'next/image';
import styles from './StationScroll.module.scss';

export function Station({ station }: { station: StationItemType }) {
  const openRadioPlayer = useRadioPlayerPopup();
  const size = 208;
  const logo = station.logo?.data;

  return (
    <button
      className={styles.Station}
      style={
        logo
          ? {
              backgroundColor: isHex(logo.dominant_color)
                ? logo.dominant_color
                : undefined,
            }
          : undefined
      }
      title={`Luister naar ${station.title}`}
      onClick={() => openRadioPlayer(station)}
    >
      {logo && (
        <Image
          src={imageUrl(logo.crop).setRatio('1:1').setWidth(size).toString()}
          alt={station.title}
          width={size}
          height={size}
        />
      )}
    </button>
  );
}
