import { GetStaticPropsContext } from 'next';
import { createWrapper, HYDRATE, MakeStore } from 'next-redux-wrapper';
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';
import {
  AnyAction,
  combineReducers,
  createStore,
  Dispatch,
  Store,
  applyMiddleware,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { middleware } from './middleware';
import { NowPlayingAction } from './nowPlaying/actions';
import { nowPlayingReducer, NowPlayingState } from './nowPlaying/reducer';
import { CookiesAction } from './cookies/actions';
import { cookiesReducer, CookiesState } from './cookies/reducer';
import { SettingsAction } from './settings/actions';
import { settingsReducer, SettingsState } from './settings/reducer';
import { StationAction } from './stations/actions';
import { stationsReducer, StationsState } from './stations/reducer';

export type HydrateAction = {
  type: typeof HYDRATE;
  payload: State;
};

export type RootAction =
  | StationAction
  | SettingsAction
  | HydrateAction
  | CookiesAction
  | NowPlayingAction;

export type State = {
  stations: StationsState;
  settings: SettingsState;
  cookies: CookiesState;
  nowPlaying: NowPlayingState;
};

export const rootReducer = combineReducers<State>({
  stations: stationsReducer,
  settings: settingsReducer,
  cookies: cookiesReducer,
  nowPlaying: nowPlayingReducer,
});

const makeStore: MakeStore<Store<State>> = () =>
  createStore(rootReducer, composeWithDevTools(applyMiddleware(middleware)));

export const wrapper = createWrapper<Store<State>>(makeStore, {
  debug: false,
});

export const useDispatch = (): Dispatch<RootAction> => useReduxDispatch();

export const useSelector: TypedUseSelectorHook<State> = useReduxSelector;

export type Context = GetStaticPropsContext & {
  store: Store<State, AnyAction>;
};
