import { SerializableObject } from './types/serializable';
import * as Sentry from '@sentry/nextjs';

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;

type Query = Record<string, string | null | number | undefined>;

export function getUrl(path: string, query: Query = {}): string {
  const url = new URL(baseUrl || '');
  url.pathname = path;
  Object.entries(query).forEach(([key, value]) => {
    if (value != null) {
      url.searchParams.append(key, value.toString());
    }
  });
  return url.href;
}

export function get(
  path: string,
  query: Query = {},
  options: RequestInit = {}
) {
  return fetch(getUrl(path, query), {
    mode: 'cors',
    ...options,
  })
    .then((response) => {
      if (response.ok) {
        return response;
      } else if (response.status > 300 && response.status !== 404) {
        Sentry.captureException(response.statusText);
      }

      throw Error(`Request rejected with status ${response.status}`);
    })
    .then((response) => response.json());
}

export function postAsFormData(
  path: string,
  body: Record<string, string | number | boolean | File | undefined>
) {
  const formData = new FormData();
  Object.entries(body).forEach(([key, value]) => {
    if (value != null) {
      formData.append(
        key,
        typeof value === 'number'
          ? String(value)
          : typeof value === 'boolean'
          ? String(value ? 1 : 0)
          : value
      );
    }
  });

  return fetch(getUrl(path), { method: 'POST', mode: 'cors', body: formData });
}

export function postAsJSON(path: string, body: SerializableObject) {
  return fetch(getUrl(path), {
    method: 'POST',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
