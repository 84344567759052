import { ActionType, createStandardAction } from 'typesafe-actions';
import { ConsentType } from '@api/types/types';

export const setConsent = createStandardAction(
  '[cookies] setConsent'
)<ConsentType>();

const actions = { setConsent };

export type CookiesAction = ActionType<typeof actions>;
