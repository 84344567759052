import { useEffect, useState } from 'react';

export function usePageVisible() {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setVisible(document.visibilityState === 'visible');

    function onChange() {
      setVisible(document.visibilityState === 'visible');
    }

    document.addEventListener('visibilitychange', onChange);
    return () => {
      document.removeEventListener('visibilitychange', onChange);
    };
  }, []);

  return visible;
}
