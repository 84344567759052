import type { StationItemType } from '@api/types/types';
import { RootAction } from '@store/index';
import { getType } from 'typesafe-actions';
import { setStations } from './actions';
import { HYDRATE } from 'next-redux-wrapper';

export type StationsState = StationItemType[];

export function stationsReducer(
  state: StationsState = [],
  action: RootAction
): StationsState {
  switch (action.type) {
    case getType(setStations): {
      return action.payload;
    }

    case HYDRATE: {
      return action.payload.stations;
    }
  }

  return state;
}
