import { useListenerId } from '@hooks/useListenerId';
import { useZipcodeResult } from '@hooks/useZipcodeResult';
import { useSelector } from '@store/index';
import { getMainStation } from '@store/stations/selectors';
import CastIcon from '@svg/cast.svg';
import { getStationUrl } from '@utils/getStationUrl';
import { memo, useContext } from 'react';
import { ChromecastReceiverAvailableContext } from '@components/Chromecast/Chromecast';
import { loadMedia } from '../../utils/chromecast';
import styles from './Player.module.css';

export const ChromeCastButton = memo(function ChromeCastButton() {
  const [zipcodeResult] = useZipcodeResult();
  const mainStation = useSelector(getMainStation);
  const lsid = useListenerId();
  const chromecastAvailable = useContext(ChromecastReceiverAvailableContext);
  const liveStreamHeaderUrl = useSelector(
    (state) => state.settings.live_stream_header_url
  );

  return (
    <button
      key="chromecast"
      disabled={!(chromecastAvailable && mainStation)}
      className={styles.PlayerButton}
      title={
        chromecastAvailable
          ? 'Luister via Chromecast'
          : 'Chromecast niet beschikbaar'
      }
      onClick={() => {
        if (!mainStation) {
          return;
        }

        if (liveStreamHeaderUrl && /http/.test(liveStreamHeaderUrl || '')) {
          loadMedia(liveStreamHeaderUrl, 'application/x-mpegURL');
        } else {
          const zip =
            zipcodeResult?.type === 'saved' &&
            zipcodeResult.countryCode === 'BE'
              ? zipcodeResult.zip
              : undefined;

          loadMedia(getStationUrl(mainStation, { zip, lsid }), 'audio/aac');
        }
      }}
    >
      <CastIcon />
    </button>
  );
});
