import { StationItemType } from '@api/types/types';
import { ActionType, createStandardAction } from 'typesafe-actions';

export const setStations = createStandardAction('[stations] setStations')<
  StationItemType[]
>();

const actions = { setStations };

export type StationAction = ActionType<typeof actions>;
