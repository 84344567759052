import { BlockOf, BlockType, ImageItemType } from '@api/types/types';
import { imageUrl } from '@utils/imageUrl';

export type OpenGraphImage = {
  url: string;
  width?: number;
  height?: number;
  alt?: string;
};

type Ratio = [1904, 1000] | [2, 1] | [1, 1];

export function getOpenGraphImage(
  image: ImageItemType,
  width: number = 1200,
  ratio: Ratio = [2, 1],
  title?: string
): OpenGraphImage {
  const url = imageUrl(image.crop)
    .setWidth(width)
    .setRatio(ratio.join(':'))
    .toString();

  const alt = title || image?.caption;
  const [w, h] = ratio;
  const factor = w / h;

  return {
    url,
    alt,
    width,
    height: Math.round(width / factor),
  };
}

export function getOpenGraphImageVariations(
  image: ImageItemType,
  title: string
) {
  return [
    getOpenGraphImage(image, 1200, [1904, 1000], title), // Facebook
    getOpenGraphImage(image, 800, [1, 1], title), // Twitter/Whatsapp
    getOpenGraphImage(image, 800, [2, 1], title), // Other
  ];
}

export function getOpenGraphImages(
  blocks: BlockOf<BlockType>[],
  title: string
): OpenGraphImage[] {
  return blocks
    .map((block) => block.block.data)
    .filter((block) => block.type === 'block_headers')
    .reduce<OpenGraphImage[]>((images, block) => {
      if (block.type === 'block_headers') {
        return block.image
          ? images.concat(getOpenGraphImageVariations(block.image.data, title))
          : images;
      }

      return images;
    }, []);
}
