import * as Sentry from '@sentry/nextjs';
import { isIOS } from '@utils/isMobile';

type AnyObject = Record<string, any>;

declare const chrome: AnyObject;

export function loadMedia(
  mediaUrl: string,
  contentType = 'audio/aac'
): Promise<void> {
  return new Promise((resolve, reject) => {
    chrome.cast.requestSession(
      function (session: AnyObject) {
        const mediaInfo = new chrome.cast.media.MediaInfo(mediaUrl);
        mediaInfo.contentType = contentType;
        const request = new chrome.cast.media.LoadRequest(mediaInfo);

        session.loadMedia(
          request,
          function () {
            resolve();
          },
          function () {
            reject();
          }
        );
      },
      function () {
        reject();
      }
    );
  });
}

let apiPromise: Promise<AnyObject> | null = null;

const getChromeCastApiPromise = () => {
  if (apiPromise) {
    return apiPromise;
  }

  apiPromise = new Promise<AnyObject>((resolve, reject) => {
    if (typeof window === 'object' && !isIOS() && document.head?.append) {
      (window as any).__onGCastApiAvailable = function castApiAvailable(
        loaded: boolean,
        errorInfo: AnyObject
      ) {
        if (loaded && typeof chrome?.cast?.SessionRequest === 'function') {
          resolve(chrome);
        } else {
          reject(errorInfo || 'SessionRequest is not a function');
        }
      };

      const script = document.createElement('script');
      script.async = true;
      script.src =
        'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1';
      script.addEventListener('error', () => {
        reject(Error('Could not load cast_sender.js'));
      });
      document.head.append(script);
    } else {
      reject(Error('Will not load chromecast framework'));
    }
  });

  return apiPromise;
};

export function waitForReceiver(
  onAvailabilityChange: (available: boolean) => void
): Promise<void> {
  return getChromeCastApiPromise().then((chrome) => {
    const { cast } = chrome;

    const sessionRequest = new cast.SessionRequest(
      cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID
    );

    const apiConfig = new cast.ApiConfig(
      sessionRequest,
      function sessionListener() {},
      function receiverListener(availability: string) {
        onAvailabilityChange(
          availability === cast.ReceiverAvailability.AVAILABLE
        );
      },
      cast.AutoJoinPolicy.ORIGIN_SCOPED
    );

    cast.initialize(
      apiConfig,
      function () {},
      function () {
        console.error(arguments);
        Sentry.captureMessage('Could not initialize chromecast api');
      }
    );
  });
}
