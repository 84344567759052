import { useRouter } from 'next/router';
import { localUrl } from '@utils/env';

export function useCurrentUrl() {
  const router = useRouter();
  const url = new URL(localUrl);
  url.pathname = router.asPath;
  return {
    url: url.href,
    path: router.asPath,
  };
}
