import { ChromeCastButton } from './ChromeCastButton';
import styles from './Player.module.css';

export function PlayerButtons() {
  return (
    <div className={styles.PlayerButtonGroup}>
      <ChromeCastButton />
    </div>
  );
}
