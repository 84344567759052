import { createStandardAction, ActionType } from 'typesafe-actions';
import { NowPlayingListType } from '@api/types/types';

export const setNowPlaying = createStandardAction(
  '[now-playing] setNowPlaying'
)<NowPlayingListType>();

const actions = { setNowPlaying };

export type NowPlayingAction = ActionType<typeof actions>;
