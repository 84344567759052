import { get, postAsFormData } from '@api/request';
import { PaginatedResponse, Response } from '@api/types/response';
import {
  JobType,
  NowPlayingListType,
  NowPlayingResponseType,
  PageType,
  PaginatedTopics,
  PlayedTrackListResponseType,
  ScheduleType,
  SettingsType,
  TopicCollectionType,
  TopicItemType,
  VotePayloadType,
  VotesResponseType,
  VotingConfirmResponseType,
  VotingRoundResponseType,
  VotingSelectedNomineeType,
} from '@api/types/types';

export async function fetchTopics({
  tags = [],
  page = 1,
  limit = 8,
}: {
  tags: string[];
  page?: number;
  limit?: number;
}): Promise<PaginatedTopics> {
  try {
    const response: PaginatedResponse<TopicItemType[]> = await get(`/topics`, {
      tags: tags.length ? tags.join(',') : null,
      page,
      limit,
    });
    return {
      topics: response.data,
      pagination: response.meta.pagination,
    };
  } catch (e) {}

  return {
    topics: [],
    pagination: {
      total: 0,
      count: 0,
      per_page: 0,
      current_page: 0,
      total_pages: 0,
    },
  };
}

export async function fetchTopic(
  slug: string
): Promise<TopicCollectionType | null> {
  try {
    const response: Response<TopicCollectionType> = await get(
      `/topics/${slug}`
    );
    return response.data;
  } catch (e) {}

  return null;
}

export async function fetchJobs(): Promise<JobType[]> {
  try {
    const response: Response<JobType[]> = await get('/jobs');
    return response.data;
  } catch (e) {}

  return [];
}

export async function fetchPage(slug: string): Promise<PageType | null> {
  try {
    const response: Response<PageType> = await get(`/pages/${slug}`);
    return response.data;
  } catch (e) {}

  return null;
}

export async function fetchPlayedTracklistByStation(
  station: string,
  options?: {
    start?: string | null;
    end?: string | null;
    date?: string | null;
  }
): Promise<PlayedTrackListResponseType> {
  try {
    const response: PlayedTrackListResponseType = await get(
      `/stations/${station}/played-tracks`,
      options
    );
    return response;
  } catch (e) {}

  return {
    meta: {
      start: 0,
      end: 24 * 60,
    },
    data: [],
  };
}

export async function fetchScheduleByStation(
  station: string,
  options?: {
    date?: string;
  }
): Promise<ScheduleType[]> {
  try {
    const response: Response<ScheduleType[]> = await get(
      `/stations/${station}/schedule`,
      options
    );
    return response.data;
  } catch (e) {}

  return [];
}

export async function fetchSettings(): Promise<SettingsType | null> {
  try {
    const response: Response<SettingsType> = await get('/settings');
    return response.data;
  } catch (e) {}

  return null;
}

export async function fetchNowPlaying(): Promise<NowPlayingListType> {
  try {
    const response: NowPlayingResponseType = await get(
      '/static/now-playing.json',
      {},
      {
        cache: 'no-cache',
      }
    );
    return response.stations;
  } catch (e) {}

  return {};
}

export const vote = (
  payload: VotePayloadType,
  roundId: number,
  selectedNominees: VotingSelectedNomineeType[],
  captchaToken: string
) => {
  const nonSuggestionNominees = selectedNominees.filter(
    (nominee) => !nominee.isSuggestion
  );
  const suggestionNominees = selectedNominees.filter(
    (nominee) => nominee.isSuggestion
  );

  let votes;
  let suggestions;

  if (nonSuggestionNominees.length) {
    votes = nonSuggestionNominees.map(({ id, motivation }) => ({
      nomineeId: parseInt(id, 10),
      extraField: motivation,
    }));
  }

  if (suggestionNominees.length) {
    suggestions = suggestionNominees.map(
      ({ secondary_name, name, motivation }) => ({
        name: name,
        secondary_name: secondary_name,
        extraField: motivation,
      })
    );
  }

  return postAsFormData('/voting/vote', {
    captcha: captchaToken,
    data: JSON.stringify({
      roundId,
      votes,
      suggestions,
      ip: '127.0.0.1',
      email: payload.email,
      extraFields: {
        first_name: payload.firstName,
        last_name: payload.lastName,
        phone: payload.phone,
      },
    }),
  });
};

export const getVotingRound = (): Promise<VotingRoundResponseType> => {
  return get(
    '/voting/round',
    {},
    { headers: { 'access-token': process.env.SECRET || '' } }
  );
};

export const getVotesByUuid = (uuid: string): Promise<VotesResponseType> => {
  return get(
    `/voting/votes/${uuid}`,
    {},
    { headers: { 'access-token': process.env.SECRET || '' } }
  );
};

export const confirmVote = (
  uuid: string
): Promise<VotingConfirmResponseType> => {
  return get(`/voting/vote/confirm/${uuid}`);
};
