import { getUrl } from '@api/request';
import { StationItemType } from '@api/types/types';
import { isMobile } from '@utils/isMobile';

export function getStationUrl(
  { slug, has_regions, station_url }: StationItemType,
  { zip, lsid }: { zip?: string; lsid?: string } = {}
) {
  return has_regions
    ? getUrl(`/stations/${slug}/url`, {
        mobile: isMobile() ? 1 : 0,
        zip,
        lsid,
      })
    : station_url && lsid
    ? `${station_url}?lsid=${lsid}`
    : station_url;
}
