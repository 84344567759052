import { useWindowSize } from '@hooks/useWindowSize';
import { useIsMounted } from '@hooks/useIsMounted';

export function useMaxTopNavCount() {
  const { windowWidth } = useWindowSize();
  const mounted = useIsMounted();

  if (!mounted) {
    return 8;
  }

  if (windowWidth >= 1450) {
    return 10;
  }

  if (windowWidth >= 1330) {
    return 9;
  }

  if (windowWidth >= 1250) {
    return 8;
  }

  if (windowWidth >= 1180) {
    return 7;
  }

  if (windowWidth >= 1024) {
    return 6;
  }

  if (windowWidth >= 920) {
    return 5;
  }

  if (windowWidth >= 800) {
    return 4;
  }

  return 0;
}
