import { SerializableValue } from '@api/types/serializable';

export const storage = {
  set<T extends SerializableValue>(key: string, value: T): void {
    if (typeof window === 'object') {
      try {
        window.localStorage.setItem(key, JSON.stringify(value));
      } catch (e) {}
    }
  },

  get<T extends SerializableValue>(key: string): T | undefined {
    if (typeof window === 'object') {
      try {
        const value = window.localStorage.getItem(key);
        return typeof value === 'string' ? JSON.parse(value) : value;
      } catch (e) {
        return undefined;
      }
    }

    return undefined;
  },
};
