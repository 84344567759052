import type { SettingsType } from '@api/types/types';
import { RootAction } from '@store/index';
import { HYDRATE } from 'next-redux-wrapper';
import { getType } from 'typesafe-actions';
import { setSettings } from './actions';

const initialState: SettingsType = {
  id: 1,
  seo_title: 'TOPradio - We Love Music!',
  seo_description:
    'TOPradio is dé dance- en urbanzender voor Vlaanderen met oor voor nieuwe muziek.',
  live_stream_url: '',
  live_stream_header_url: '',
  live_stream_header_title: '',
  live_stream_header_visible: false,
  socials: [],
  main_station_id: 1,
  stations: {
    data: [],
  },
  editorPick: {
    data: {
      id: 1,
      highlightedTopic: null,
      homepageCarousel: {
        data: [],
      },
      listeningHeaderImage: null,
    },
  },
  navigation: {
    data: {
      id: 1,
      top: {
        data: [],
      },
      hamburger: {
        data: [],
      },
      leftFooter: {
        data: [],
      },
      middleFooter: {
        data: [],
      },
      rightFooter: {
        data: [],
      },
    },
  },
  seoImage: null,
  shopHeaderImage: null,
  backgroundImage: null,
};

export type SettingsState = SettingsType;

export function settingsReducer(
  state: SettingsState = initialState,
  action: RootAction
): SettingsState {
  switch (action.type) {
    case getType(setSettings): {
      return action.payload || state;
    }

    case HYDRATE: {
      return action.payload.settings || state;
    }
  }

  return state;
}
