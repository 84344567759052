import { Middleware } from 'redux';
import { getType } from 'typesafe-actions';
import { setConsent } from './cookies/actions';
import { RootAction } from './index';
import { storage } from '@utils/storage';

export const middleware: Middleware = () => (next) => (action: RootAction) => {
  const returnValue = next(action);

  switch (action.type) {
    case getType(setConsent): {
      storage.set('cookie_consent', action.payload);
    }
  }

  return returnValue;
};
