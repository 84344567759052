import Logo from '@assets/svg/logo.svg';
import { useIsMounted } from '@hooks/useIsMounted';
import { setConsent } from '@store/cookies/actions';
import { getCookieConsent } from '@store/cookies/selectors';
import { useDispatch, useSelector } from '@store/index';
import React from 'react';
import styles from './CookieWall.module.css';

export function CookieWall() {
  const dispatch = useDispatch();
  const consent = useSelector(getCookieConsent);
  const mounted = useIsMounted();

  // Don't render anything on the server.
  if (!mounted || consent !== 'pending') {
    return <div />;
  }

  return (
    <div className={styles.CookieWall}>
      <div className={styles.CookieWallHeader}>
        <h2>Mogen we cookies gebruiken?</h2>
        <Logo />
      </div>

      <div className={styles.CookieWallBody}>
        <p>
          Via cookies verzamelen we informatie. Dit laat ons toe het gebruik van
          de site te analyseren, sociale media te integreren, content en
          marketing te personaliseren, en je gerichte advertenties te tonen op
          onze sites en apps. Meer uitleg vind je terug in ons privacybeleid en
          cookiebeleid.
        </p>
        <p>
          Geef hier onder toestemming of stel je eigen voorkeuren in. Je keuze
          geldt voor al onze media en je kan deze op elk moment opnieuw
          aanpassen worden door onderaan de pagina op cookie-instellingen te
          klikken.
        </p>
      </div>

      <div className={styles.CookieWallFooter}>
        <button
          className={styles.CookieWallConfirmButton}
          onClick={() => {
            dispatch(setConsent('accepted'));
          }}
        >
          Ja, Ik ga Akkoord!
        </button>
        <button
          className={styles.CookieWallSettingsButton}
          onClick={() => {
            dispatch(setConsent('declined'));
          }}
        >
          Nee, liever niet
        </button>
      </div>
    </div>
  );
}
